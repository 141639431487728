import initScene from './scene.js';
import { createApp } from 'vue';
import UI from './ui/ui.vue';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

createApp(UI).mount("#osd");
initScene();

// Curatorial text
const intro = document.getElementById("intro");
const toggle = intro.querySelector(".activate");
const close = intro.querySelector(".close");
toggle.addEventListener("click", () => {
  intro.classList.toggle("active");
});
close.addEventListener("click", () => {
  intro.classList.remove("active");
});
