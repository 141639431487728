import { SphereGeometry,
         EdgesGeometry,
         LineBasicMaterial,
         LineSegments,
         MeshBasicMaterial,
         Object3D,
         DoubleSide } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import shuffle from 'lodash.shuffle';
import Artists from './artists.js';

export function baseSphere () {
  const sphereGeom = new SphereGeometry(40, 42, 42);
  const sphereGeomWire = new EdgesGeometry(sphereGeom);
  const material = new LineBasicMaterial({color: 0x777777, linewidth: 1});
  const wf = new LineSegments(sphereGeomWire, material);
  wf.computeLineDistances();
  wf.rotation.z = 0.2;
  return wf;
}

export async function loadBlob (url) {
  const loader = new GLTFLoader();
  const model = await loader.loadAsync(url);
  model.scene.traverse((node) => {
    if (!node.isMesh) return;
    node.material.color.set("black");
    node.material.wireframe = true;
  });
  return model;
}

export function baseBlob (model) {
  const obj = model.scene.clone();
  obj.traverse((node) => {
    if (!node.isMesh) return;
    node.material = new MeshBasicMaterial({ color: "black" });
    node.material.side = DoubleSide;
    node.material.wireframe = true;
  });
  obj.scale.multiplyScalar(1.3);
  return obj;
}

export function teamSegments (model) {
  const meta = [
    {label: 'team', href: '/anabasis.html#team'},
    {label: 'experts', href: '/anabasis.html#experts'},
    {label: 'about', href: '/anabasis.html#about'},
    {label: 'curatorial conversation', href: '/assets/Curatorial Conversation.pdf'}
  ];
  this.rotStep = 0.001;
  this.objects = shuffle(model.children).slice(0, meta.length);
  this.objects.forEach(o => o.userData.meta = meta.pop());
  this.update = function () {
    this.objects.forEach((node, idx) => {
      node.rotation.z -= (idx + 1) * this.rotStep;
    });
  };

  return this;
}

export function blobArray (model, color) {
  this.phaseStep = 0.00025;

  this.blobs = shuffle(Artists).map((artist, idx) => {
    const obj = model.scene.children[0].clone();
    obj.material = new MeshBasicMaterial({ color });
    obj.material.wireframe = true;
    obj.rotation.x = -(Math.PI / 2);
    obj.position.z = idx * -3.0;
    obj.userData.artist = artist;
    return obj;
  });

  this.base = new Object3D();
  this.base.scale.multiplyScalar(0.1);
  this.base.position.z = 1;
  this.blobs.forEach(b => this.base.add(b));

  this.phase = Math.random();

  this.update = function () {
    this.phase = (this.phase + this.phaseStep) % 1;
    this.base.children.forEach((obj, idx) => {
      const phasePI = Math.PI * 2 * this.phase;
      obj.position.y = Math.sin(Math.PI * (idx / 10) * 2 + phasePI) * 4;
      obj.position.x = Math.cos(Math.PI * (idx / 10) * 2 + phasePI) * 4;
    });
  }
}
