<template>
    <span v-if="artist">
        Artist: {{ artist.name }} [{{ artist.location }}]
    </span>
    <span v-else="meta">
        {{ meta }}
    </span>
    <teleport to="body" v-if="view">
        <VueEasyLightbox
            :imgs="lightboxImgs"
            :visible="lightbox"
            @hide="closeImage"></VueEasyLightbox>
        <div class="overlay">
            <button v-if="!lightbox" class="close" aria-label="close" v-on:click="closeView">&times;</button>
            <header>
                <h1 class="artist-name">{{ artist.name }}</h1>
                <nav>
                    <ul class="tabs">
                        <li><a class="tab"
                               :class="{ active: tab == 'project' }"
                               @click="tab = 'project'" :href="hash(artist, 'project')"
                            >Project</a>
                        </li>
                        <li><a class="tab"
                               :class="{ active: tab == 'bio' }"
                               @click="tab = 'bio'" :href="hash(artist, 'bio')"
                            >Bio</a>
                        </li>
                    </ul>
                </nav>
            </header>
            <div class="info bio" v-if="tab == 'bio'">
                <div class="artist-info">
                    <p class="specializes">{{ artist.specializes }}</p>
                    <p class="links">
                        <a v-for="(href, label) in artist.links" :href="href">{{ label }}</a>
                    </p>
                    <img class="portrait" :src="'artists/' + artist.identifier + '.png'" alt="artist photo">
                </div>
                <Markdown v-if="artist.bioFull" :source="artist.bioFull" />
                <span v-else>/loading/</span>
            </div>
            <div class="info project" v-else-if="tab == 'project'">
                <div class="description">
                    <h2 class="title">{{ proj.title }}</h2>
                    <p class="genre">{{ proj.genre }}</p>
                    <Markdown :source="proj.description" html />
                </div>

                <template v-for="item in proj.items">
                    <video class="item" controls controlsList="nodownload" v-if="item.type == 'video'" :src="itemSrc(artist, item.src)"
                           :poster="itemSrc(artist, item.poster)" :loop="item.loop ? true : null"></video>
                    <div class="item labelled" v-if="item.type == 'audio'">
                        <button class="play-pause"
                                @click="toggleAudio(item)">
                            <span v-if="item.active" class="stop" aria-label="stop">&times</span>
                            <img v-else src="assets/icon-play.svg" alt="play">
                            <teleport to="#audio-container">
                                <audio controls
                                       controlsList="nodownload"
                                       :class="{ active: item.active }"
                                       :src="itemSrc(artist, item.src)"
                                       :ref="el => item.audioEl = el"
                                       @play="item.active = true"></audio>
                            </teleport>
                        </button>
                        <span class="label">
                            {{ item.label }}
                        </span>
                    </div>
                    <img class="item" v-if="item.type == 'image'" :src="itemSrc(artist, item.src)" alt="">
                    <a class="item file labelled" target="_blank" v-if="item.type == 'file'" :href="itemSrc(artist, item.href)">
                        <img class="icon" src="assets/icon-text.svg" alt=""> <span class="label">{{ item.label }}</span>
                    </a>
                    <iframe class="item" v-if="item.type == 'iframe'" :src="itemSrc(artist, item.src)"></iframe>
                    <div class="item raw" v-if="item.type == 'raw'" v-html="item.content"></div>
                </template>
            </div>
        </div>
    </teleport>
</template>

<script>
 import { computed, ref, reactive } from 'vue';
 import bus from '../bus.js';
 import axios from 'axios';
 import Markdown from 'vue3-markdown-it';
 import VueEasyLightbox from 'vue-easy-lightbox';
 import Artists from '../artists.js';
 import Projects from '../../projects/projects.yaml';

 const ARTIST_HASH_RE = /^#([a-zA-Z-]+):(bio|project)$/;

 const tab = ref('project');

 export default {
     components: {
         Markdown,
         VueEasyLightbox,
     },
     setup () {
         const artist = ref(null);
         const meta = ref(null);
         const view = ref(false);
         const lightboxImgs = ref([]);
         const lightbox = ref(false);
         const projects = reactive(Projects);
         const proj = computed(() => artist.value ? projects[artist.value.identifier] : null);

         function hash(artist, tab) {
             return '#' + artist.identifier + ':' + tab;
         }

         function itemSrc(artist, src) {
             return 'projects/' + artist.identifier + '/' + src;
         }

         function routeView() {
             if (!window.location.hash) {
                 closeView();
                 return;
             }

             const matched = window.location.hash.match(ARTIST_HASH_RE);
             if (!matched) {
                 console.log("Invalid hash address");
                 return;
             }
             const [_, artistId, tabId] = matched;

             const artist = Artists.find(a => a.identifier == artistId);
             if (!artist) {
                 console.log("Artist not found: ", artistId);
                 return;
             }

             tab.value = tabId;
             bus.emit('selectArtist', artist);
             bus.emit('freezeScene');
             bus.emit('activateArtist');
         }


         function closeView() {
             bus.emit('resumeScene');
             view.value = false;
             document.body.classList.remove("noscroll");
             window.location.hash = '';
         }

         function showImage(url) {
             lightboxImgs.value = url;
             lightbox.value = true;
         }
         function closeImage() {
             lightbox.value = false;
         }

         bus.on('selectArtist', (a) => {
             artist.value = a;
         });
         bus.on('selectMeta', (m) => {
             meta.value = m;
         });
         bus.on('activateArtist', async function () {
             view.value = true;
             document.body.classList.add("noscroll");
             window.location.hash = hash(artist.value, tab.value);
             if (!artist.value.bioFull) {
                 const response = await axios.get(`artists/${artist.value.bio}`);
                 artist.value.bioFull = response.data;
             }
         });

         window.addEventListener('load', routeView);
         window.addEventListener('popstate', routeView);

         function toggleAudio(item) {
             if (item.active) {
                 item.audioEl.pause();
                 item.active = false;
             } else {
                 item.active = true;
                 item.audioEl.play();
             }
         }

         return {
             artist,
             hash,
             proj,
             itemSrc,
             meta,
             tab,
             view,
             closeView,
             lightbox,
             lightboxImgs,
             showImage,
             closeImage,
             toggleAudio,
         };
     }
 }
</script>
