import {
  PerspectiveCamera,
  Raycaster,
  Scene,
  Vector2,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { baseSphere, loadBlob, baseBlob, blobArray, teamSegments } from './objects.js';
import bus from './bus.js';
import Hammer from 'hammerjs';

const colors = {
  highlight: 0x0B7C66,
  base: 0x000000,
};
export const root = document.getElementById("scene");

const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
const raycaster = new Raycaster();
let childBlobs = null;
let teamRings = null;

function initInteraction () {
  const hammer = new Hammer(root);
  hammer.on("tap press", (evt) => {
    const ptr = evt.pointers[0];
    const artist = pointerIntersect(ptr);
    const meta = pointerIntersectTeam(ptr);
    if (artist) {
      bus.emit("selectArtist", artist);
      activateArtist(artist);
    } else if (meta) {
      bus.emit("selectMeta", meta.label);
      activateMeta(meta);
    }
  });
  root.addEventListener('mousemove', (evt) => {
    if (pointerIntersect(evt) || pointerIntersectTeam(evt)) {
      hoverCursor(true);
    } else {
      hoverCursor(false);
    }
  });
}

function activateArtist(artist) {
  bus.emit("activateArtist");
  bus.emit("freezeScene");
}

function activateMeta(meta) {
  window.location.href = meta.href;
}

function pointerCoord(evt) {
  return new Vector2(
    (evt.clientX / window.innerWidth) * 2 - 1,
    -(evt.clientY / window.innerHeight) * 2 + 1
  );
}

function hoverCursor(hover) {
  if (hover) {
    root.style.cursor = "pointer";
  } else {
    root.style.cursor = "grab";
  }
}

function highlight(obj) {
  obj.material.color.set(colors.highlight);
}
function unhighlight(obj) {
  obj.material.color.set(colors.base);
}

export function pointerIntersect(evt) {
  const objects = childBlobs.base.children;
  camera.updateMatrixWorld();
  raycaster.setFromCamera(pointerCoord(evt), camera);
  const intersects = raycaster.intersectObjects(objects, true);
  if (intersects.length) {
    const i = intersects[0];
    const artist = i.object.userData.artist;
    objects.forEach(unhighlight);
    highlight(i.object);
    bus.emit('selectArtist', artist);
    return artist;
  } else {
    objects.forEach(unhighlight);
    bus.emit('selectArtist', null);
    return null;
  }
}

export function pointerIntersectTeam(evt) {
  const objects = teamRings.objects;
  const intersects = raycaster.intersectObjects(objects, true);
  if (intersects.length) {
    const i = intersects[0];
    const meta = i.object.userData.meta
    objects.forEach(unhighlight);
    highlight(i.object);
    bus.emit('selectMeta', meta.label);
    return meta;
  } else {
    objects.forEach(unhighlight);
    bus.emit('selectMeta', null);
    return null;
  }
}

export default async function initScene () {
  const scene = new Scene();
  const renderer = new WebGLRenderer({
    antialias: true,
    canvas: document.getElementById("scene"),
  });

  /* === renderer === */
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0xffffff, 255);

  /* === camera === */
  camera.lookAt(0, 0, -100);
  setCamPosition(camera);
  scene.add(camera);

  /* === sphere === */
  scene.add(baseSphere());

  /* === allow pausing === */
  let frozen = false;
  bus.on("freezeScene", () => { draw(false); frozen = true });
  bus.on("resumeScene", () => { frozen = false; draw() });

  /* === base blob === */
  const baseBlobModel = await loadBlob('../assets/anabasis-object-split.glb');
  const blobModel = await loadBlob('../assets/anabasis-object.glb');
  let blob = baseBlob(baseBlobModel);
  childBlobs = new blobArray(blobModel, colors.base)
  teamRings = teamSegments(blob);
  scene.add(blob);
  scene.add(childBlobs.base);

  /* === controls === */
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.rotateSpeed = -0.05;
  controls.enableZoom = true;
  controls.enablePan = false;
  controls.screenSpacePanning = true;

  function setCamPosition (camera, factor = 2) {
    camera.position.z = -2;
    camera.position.x = (Math.random() - 0.5) * factor;
    camera.position.y = (Math.random() - 0.5) * factor;
  };

  /* === update === */
  function update() {
    childBlobs.update();
    teamRings.update();
  }

  /* === draw === */
  function draw(doUpdate = true) {
    if (!frozen && doUpdate) update();
    renderer.render(scene, camera);
    if (!frozen) requestAnimationFrame(draw);
  }
  draw();

  /* === window resizing === */
  function resize(){
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
    if (frozen) draw();
  }
  window.addEventListener('resize', resize);
  window.addEventListener('orientationchange', resize);
  initInteraction();
};
