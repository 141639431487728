export default [
  {
    name: "Dovilė Aleksaitė",
    identifier: "dovile-aleksaite",
    location: "Berlin",
    specializes: "Video, 3d generated images, sound",
    bio: "dovile-aleksaite.md",
    nextcloud: "jDwjbAbR24e6Jz3",
    photoCredits: "Leonor von Salisch",
    links: {
      "Website": "http://www.aleksaite.com/",
      "Facebook": "https://www.Facebook.com/dovile.aleksaite/",
      "Instagram": "https://www.Instagram.com/dovile.aleksaite/",
    },
  },
  {
    name: "Yana Bachynska",
    identifier: "yana-bachynska",
    location: "Lviv",
    specializes: "Film, public art",
    bio: "yana-bachynska.md",
    nextcloud: "jG68cAdoQZ8f5Tq",
    links: {
      "Facebook": "https://www.Facebook.com/jhgjhkjfdy",
      "Instagram": "https://www.Instagram.com/holova_janusa/",
    },
  },
  {
    name: "Bohdan Bunchak",
    identifier: "bohdan-bunchak",
    location: "Kyiv",
    specializes: "Visual arts, critique, theology",
    bio: "bohdan-bunchak.md",
    nextcloud: "3iKQbZNZK3sPGR5",
    photoCredits: "Anton Saenko",
    links: {
      "Website": "https://bohdanbunchak.com/",
      "Instagram": "https://www.Instagram.com/bohdan_bunchak/",
    },
  },
  {
    name: "Tiny Domingos",
    identifier: "tiny-domingos",
    location: "Berlin",
    specializes: "Visual arts",
    bio: "tiny-domingos.md",
    nextcloud: "mgQipEcjKiKJN9M",
    links: {
      "Website": "https://www.tinydomingos.com/",
      "Facebook": "https://www.Facebook.com/tinydomingos",
      "Instagram": "https://www.Instagram.com/tdomingos/",
    },
  },
  {
    name: "Oscar Lebeck",
    identifier: "oscar-lebeck",
    location: "Leipzig",
    specializes: "Media art",
    bio: "oscar-lebeck.md",
    nextcloud: "fgsRyPx76W2d49H",
    links: {
      "Website": "https://oscarlebeck.de/",
    },
  },
  {
    name: "Neue Judische Kunst",
    identifier: "neue-judische-kunst",
    location: "Kyiv",
    specializes: "Multidisciplinary Arts",
    bio: "neue-judische-kunst.md",
    nextcloud: "sds5SpX8Ky3KGQT",
    links: {
      "Website": "http://neuejudischekunst.space/",
      "Facebook": "https://www.Facebook.com/Neue-Judische-Kunst-112383707563244",
    },
  },
  {
    name: "Bohdan Svyrydov",
    identifier: "bohdan-svyrydov",
    location: "Cherkasy",
    specializes: "Digital drawing, Generative art, Video, Photo, Painting",
    bio: "bohdan-svyrydov.md",
    nextcloud: "kXp7ZYX4DmN234r",
    links: {
      "Website": "https://bohdansvyrydov.com/",
      "Instagram": "https://www.Instagram.com/bohdan.svyrydov/",
    },
  },
  {
    name: "Paul Wiersbinski",
    identifier: "paul-wiersbinski",
    location: "Berlin",
    specializes: "Fine Arts, Performance, Video, Installation",
    bio: "paul-wiersbinski.md",
    nextcloud: "T75pZXAz7xgfowZ",
    photoCredits: "Urbane Kuenste Ruhr",
    links: {
      "Lacuna Lab | Paul Wiersbinski": "https://lacunalab.org/members/paul-wiersbinski/",
      "YouTube": "https://www.youtube.com/watch?v=liVA-5IwqHM",
    },
  },
  {
    name: "Vitaly Yankovy",
    identifier: "vitaly-yankovy",
    location: "Vinnytsia",
    specializes: "3D modeling, animation, video, drawing",
    bio: "vitaly-yankovy.md",
    nextcloud: "Ft5GZFSyRE27k4o",
    links: {
      "Vimeo": "https://vimeo.com/user36297424",
      "Facebook": "https://www.Facebook.com/vitaliy.yankovy/",
    },
  },
  {
    name: "Hana Yoo",
    identifier: "hana-yoo",
    location: "Berlin",
    specializes: "Film/Video, Computer-generative, Multimedia installation",
    bio: "hana-yoo.md",
    nextcloud: "5AJXKXDzA5ZN5my",
    photoCredits: "Atsushi Kakefuda",
    links: {
      "Website": "http://yoohana.net/",
      "Vimeo": "https://vimeo.com/hanasoma",
      "Instagram": "https://www.instagram.com/hanayoo.oooo/",
    },
  },
]
