bohdan-bunchak:
  title: "The gift, the answer, the forgiveness, and the miracle"
  genre: "single channel video, HD, color, stereo, 5’16’’, 2021"
  nosnap: true
  description: |
    _Kateryna Berlova directly participated in the production of this work._

    - To speak in the most direct manner means to speak to the Lord.
    - No equality, no justice. This means humility and mercy.
    - Be vigilant, for there is no death.
    - The Lord neglects my lack of faith.
    - Regarding the first point, there is a necessity to speak so and in no other way.
  items:
    - type: video
      src: "The gift, the answer, the forgiveness, and the miracle.mp4"
      poster: "The gift, the answer, the forgiveness, and the miracle.jpg"
bohdan-svyrydov:
  title: "Swiss-Knife-Man"
  genre: "AI generated images. Video: mp4; 00:00:40; 2048x2048px, 2021"
  description: |
    The “Swiss-Knife-Man” project explores the limits of improving the
    human body. What if we already had all we needed inside our bodies
    and could change our body parts with just a will. As easy as snapping your fingers.

    Where is the moment when the deconstruction of the body turns into
    destruction? We have many devices and stuff around us that helps
    us perform without the need to change the body – mobile phones,
    home electronics, kitchen supplies, healthcare issues, etc. On the
    other hand, some people want to change their bodies without any
    practical reason. The body is what limits us and at the same time
    makes it possible to interact with the world. The body is the
    material we have to work with, and it has its strict borders,
    beyond which we still cannot go.

    _Artist, Sound designer: Bohdan Svyrydov_  
    _CV engineer: Dan Antoshchenko_
  items:
    - type: video
      src: "SWKNMN-1-2048x2048-final.mp4"
      poster: "SWKNMN-1-2048x2048-final.jpg"
      loop: true
    - type: video
      src: "Comp-SWKNMN-2-2048x2048-final.mp4"
      poster: "Comp-SWKNMN-2-2048x2048-final.jpg"
      loop: true
    - type: video
      src: "Comp-SWKNMN-3-2048x2048-final.mp4"
      poster: "Comp-SWKNMN-3-2048x2048-final.jpg"
      loop: true
dovile-aleksaite:
  title: "Brilliance Injured"
  genre: "single channel video, HD, color, stereo, 7'53'', 2021"
  nosnap: true
  description: |
    Based on the divination method with the ancient Chinese Book of
    Changes (I-Ching) as a guidance for an intuitive and creative
    system, the short video essay was produced with macro lenses,
    filming natural elements, drawings on paper and paintings on silk.

    One method of consulting I-Ching is to throw coins which imply
    different types of hexagrams that can be looked up in a
    text. They have often required intuitive interpretations and
    acquired cosmological significance. This method was applied
    everyday during the creative process and research for the
    video. The poetic language and nature of the work drew inspiration
    from the readings to the specific hexagrams and commentaries.
  items:
    - type: video
      src: "Brilliance Injured_Aleksaite_2.mp4"
      poster: "Brilliance Injured_Aleksaite_2.jpg"
hana-yoo:
  title: "Wir fingen den Schattenfisch, seht! (We caught the shadow fish, look!)"
  genre: "single channel video, 4K, color, stereo, 5’49’’, 2021"
  nosnap: true
  description: |
    <div lang="de">

    Sie blieben verflochten zu Netzen-  
    von obenher zerren sie dran&hellip;  
    O messerumfunkelte Augen:  
    Wir fingen den Schattenfisch, seht!  

    *Aus dem Meer, Paul Celan*

    </div>

    In Paul Celan's poetry, we follow his gaze. In that gaze, we
    experience the landscape upside down: the foreground becomes the
    background, the background turns into the foreground. Matters lose
    their weight and defy gravity. Anabasis and Catabasis ‒ moving
    forward, backward, up, and down, ‘where to’ is perhaps no longer
    meaningful. All that remains might be the moment of transformation
    or its afterimage.

    *\*Recommend to watch the video with headphones*
  items:
    - type: video
      src: "Schattenfisch_2021.mp4"
      poster: "Schattenfisch_2021.jpg"
neue-judische-kunst:
  title: "Oscillating broken pillars"
  genre: "a play in two acts, 2021"
  description: |
    A critique of the modern repressive machine of adapting refugees
    into present capitalist discourse. Written in the footsteps of
    Paul Celan's poetics.
  nosnap: true
  items:
    - type: audio
      label: "Listen to the play"
      src: "Oscillating broken pillars.mp3"
    - type: file
      icon: icon-text.svg
      label: "Read in English"
      href: "Oscillating broken pillars.pdf"
    - type: file
      icon: icon-text.svg
      label: "Read in Russian"
      href: "Колеблющиеся_сломанные_столбы_финал.pdf"
oscar-lebeck:
  title: "Trek"
  description: |
    For this work "Trek" former deportation stations were sought. In
    Nazi Germany, over 50.000 people were deported only from Berlin
    train stations beginning with 1941 till 1945.
    
    By connecting two layers of images, transparent volume models of
    the wagons are subsequently integrated and spatial cubatures
    inserted into the empty space in the picture. This artistic
    approach shows what once was and at the same time emphasises what
    no longer is. The work reflects on the perception of places
    steeped in history and stimulates thinking about the organised
    deportation and murder of the Jews.
  items:
    - type: image
      src: "deport-a1.jpg"
    - type: image
      src: "deport-b1.jpg"
    - type: image
      src: "deport-g1.jpg"
    - type: image
      src: "deport-m1.jpg"
    - type: image
      src: "deport-s1.jpg"
    - type: image
      src: "deport-t1.jpg"
paul-wiersbinski:
  title: "PIA16881"
  genre: "single channel video, HD, color, stereo, 4’00’’, 2021"
  nosnap: true
  description: |
    Gaga dance classes were the way to explore notions of
    process-based self-transformation, which were paralleled by
    ephemeral recordings and got mixed with sounds of the ancient
    universe.

    *Thanks to  
    Catharina Neumann*
  items:
    - type: video
      src: "PIA16881 [637378142].mp4"
      poster: "PIA16881 [637378142].jpg"
tiny-domingos:
  title: "Jenseits der Mirabeau-Brücke (Beyond the Mirabeau bridge)"
  nosnap: true
  genre: "single channel video, HD, color, stereo, 3’52’’, 2021"
  description: |
    Paul Celan, a poet with a transnational and multilingual
    trajectory, committed suicide on the Mirabeau Bridge during the
    night of 19<sup>th</sup>-20<sup>th</sup> April 1970. The name of
    the bridge is also the title of a famous poem by the French poet
    Guillaume Apollinaire, which Celan had translated.

    The video starts from this mythical place of amorous loss and the
    passage of time to evoke and pursue some of the subjects set out
    in Paul Celan's work in a quantum perspective where the deep time
    of geology, the plasticity of clay and of the word, the quest for
    identity and metamorphosis, the decentring of the human being and
    curiosity as a driving force for overcoming the frontiers and for
    reconnection are superimposed.
  items:
    - type: video
      src: "Beyond the Mirabeau bridge.mp4"
      poster: "Beyond the Mirabeau bridge.jpg"
yana-bachynska:
  title: "What is to be done?"
  genre: "single channel video, 4K, color, stereo, 18'35'', 2021"
  nosnap: true
  description: |
    As an author, I hope this film doesn't need any description or
    explanation. Nevertheless, I should say a few words about how and
    why this film was made. The title "What is to be done?" refers to
    the eponymous book by A. Chernyshevski. This is a permanently
    relevant question, so why not make a film out of it?

    “Illegal actions” were absolutely necessary as they were congruent
    to the ideas manifested.
  items:
    - type: video
      src: "so-robyty.mp4"
      poster: "so-robyty.jpg"
vitaly-yankovy:
  title: "Profound / profane memory"
  genre: "single channel video, full HD, color, stereo, 4’46’’, geotag visualization, AR object, the action with AR object in the city landscape, essay, 2021"
  description: |
    In the center of “Profound / profane memory” is the point of
    contact between everyday life and the allocated place of
    memory. Such a point is the transit space of the sidewalk near the
    entrance to the necropolis at the Jewish cemetery in
    Chernivtsi. Continuing my practice of subjective visualization of
    domestic digital activity, I visualize a geotag that marks the
    entrance to the memorial.
  items:
    - type: video
      src: "anabasis_montage_final_v2.mp4"
      poster: "anabasis_montage_final_v2.jpg"
    - type: iframe
      src: "3dmodel.html"
    - type: raw
      content: |
        <a class="ar-link" href="https://www.instagram.com/ar/480931483055885/">
        <img class="icon" src="projects/vitaly-yankovy/yankovy-shape.png" alt="">
        <span>Try to use the AR effect in Instagram to interact with Profound / profane memory</span>
        </a>
